@import '../../Styles/theme';
@import '../../Styles/screens';
@import '../../Styles/buttons';
@import '../../Styles/loading';

.login-norm {
  background-image: none;
  background-color: theme-color('white');
  height: 100%;
  position: relative;

  @media (min-width: $screen-lg) {
    background-size: 65%;
    //background-image: url('../../Assets/Images/Login-Screen-Art.jpg');
    background-position: bottom right;
    background-repeat: no-repeat;
  }
}

.login-norm__title {
  border-bottom: 1px solid #979797;
  color: #f09732;
  font-size: 2.0rem;
  font-weight: 500;
  margin: 0;
  padding: 1em 0 1em 1.3em;
  text-align: left;

  @media (min-width: $screen-lg) {
    font-size: 2rem;
    margin-left: 0.5em;
    padding: 2em 0 1em 0;
    width: 75%;
  }
}
.login {
    background-image: url('../../Assets/Images/Blue-Corporate-Background.jpg');
    background-position: left;
    background-repeat: no-repeat;
    background-size: cover;
    display: grid;
    grid-template-rows: 4em auto 3em;
    grid-template-areas:
      "header"
      "content"
      "footer";
    height: calc(var(--vh, 1vh) * 100);
  
    @media (min-width: $screen-lg) {
      background: none;
      grid-template-rows: 100%;
      grid-template-columns: 30% 70%;
      grid-template-areas:
      "header content";
      height: 100vh;
    }
  }

.login-norm__subtitle {
  color: #084575;
  font-size: 1em;
  font-weight: 500;
  margin: 0;
  padding: 1em 0 2em 2em;
  text-align: left;

  @media (min-width: $screen-lg) {
    font-size: 1.2rem;
    padding-left: 1.6em;
  }
}

.login-norm__form {
  padding: 0 2em;

  @media (min-width: $screen-lg) {
    width: 22em;
  }
}

.login-norm__input {
  width: 100%;

  > label {
    transform: translate(14px, 14px) scale(1);
  }
}

.login-norm__error {
  color: theme-color('red');
  display: block;
  font-size: 0.6rem;
  margin-bottom: 1.5em;
  text-align: left;
  visibility: hidden;
}

.login-norm__error--visible {
  visibility: visible;
 
  &:last-of-type {
    margin-bottom: 0;
  }
}

.login-norm__error--no-margin {
  margin-bottom: 0;
}

.login-norm__forgotpw {
  background: none;
  border: none;
  display: block;
  color: #00acea;
  cursor: pointer;
  font-size: 0.8rem;
  padding: 0.5em 0 1.5em;
  outline: none;
}
.reset-button{
  display: flex;
}
.login-norm__email_link {
  @extend .button;
  @extend .button--primary;

  width:100%;

  &:disabled {
    @extend .button--disabled;
  }
}
.login-norm__forgot{
  @extend .button;
  @extend .button--primary;
  width: 100%;
  &:disabled {
    @extend .button--disabled;
  }
}
.login-norm__login {
  @extend .button;
  @extend .button--primary;

  width: 100%;
  &:disabled {
    @extend .button--disabled;
  }
}
.login-norm__login_button {
  @extend .button;
  @extend .button--primary;

  float: left;
  width: 100px;
  margin-right: 10px;

  

  &:disabled {
    @extend .button--disabled;
  }
}
.login-norm__login_reset_btn{
  @extend .button;
  @extend .button--primary;

  float: left;
  width: 100px;
  margin-right: 10px;

  &:disabled {
    @extend .button--disabled;
  }
}


.login-norm__illustration-cont {
  bottom: -4em;
  position: absolute;
  right: 0;
  transform: scaleX(-1);
  width: 40%;

  @media (min-width: $screen-lg) {
    display: none;
  }
}

.login-norm__illustration {
  transform: scaleX(-1);
  width: 100%;
}
.login-header {
    align-items: center;
    display: flex;
    height: 100%;
    justify-content: center;
  }
  
  .login-header__logo-cont {
    width: 14em;
    
    img {
      width: 100%;
    }
  
    @media (min-width: $screen-lg) {
      width: 75%;
    }
  }
  .login__header {
    grid-area: header;
  
    @media (min-width: $screen-lg) {
      align-items: center;
      background-image: url('../../Assets/Images/Blue-Corporate-Background.jpg');
      background-position: center;
      background-repeat: no-repeat;
      background-size: cover;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
    }
  }
  .login__content {
    grid-area: content;
  
    @media (min-width: $screen-lg) {
      padding-left: 3em;
    }
  }
